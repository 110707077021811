import Image1 from './images/summer_particles.png'
import Image2 from './images/sea_breath.png'
import Image3 from './images/sea_breath_2.png'
import Image4 from './images/floral_topaz.png'
import Image5 from './images/img_8716.png'
import Image6 from './images/img_8733.png'
import Image7 from './images/img_8719.png'
import Image8 from './images/pineapple_advenure_1.png'
import Image9 from './images/pineapple_adventure_2.png'
import Image10 from './images/pineapple_adventure_3.png'
import Image11 from './images/kin_ro_zone.png'
import Image12 from './images/exotic_geometry.png'
import Image13 from './images/exotica.png'
import Image14 from './images/magician_ring.png'
import Image15 from './images/glam_night.png'

export default {
  collection: [
    {
      index: 0,
      name: 'Summer Particles',
      source: Image1,
    },
    {
      index: 1,
      name: 'Sea Breath',
      source: Image2,
    },
    {
      index: 2,
      name: 'Sea Breath',
      source: Image3,
    },
    {
      index: 3,
      name: 'Floral Topaz',
      source: Image4,
    },
    {
      index: 4,
      name: ' ',
      source: Image5,
    },
    {
      index: 5,
      name: ' ',
      source: Image6,
    },
    {
      index: 6,
      name: ' ',
      source: Image7,
    },
    {
      index: 7,
      name: 'Pineapple Adventure',
      source: Image8,
    },
    {
      index: 8,
      name: 'Pineapple Adventure',
      source: Image9,
    },
    {
      index: 9,
      name: 'Pineapple Adventure',
      source: Image10,
    },
    {
      index: 10,
      name: 'Kin Ro Zone',
      source: Image11,
    },
    {
      index: 11,
      name: 'Exotic Geometry',
      source: Image12,
    },
    {
      index: 12,
      name: 'Exotica',
      source: Image13,
    },
    {
      index: 13,
      name: 'Magician Ring',
      source: Image14,
    },
    {
      index: 14,
      name: 'Glam Night',
      source: Image15,
    },
  ],
};
